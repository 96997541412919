import React from "react";

const NotFound = () => {
  return (
    <div className="not_found_container">
      <div>Error 4004</div>
      <div>Page not found</div>
    </div>
  );
};

export default NotFound;
