import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";


// Your Firebase config here
const firebaseConfig = {
    apiKey: "AIzaSyBF47gSfHzXqRGjC8cbNil8oUlLOJpJt3A",
    authDomain: "react-mcity-c3dd5.firebaseapp.com",
    databaseURL: "https://react-mcity-c3dd5.firebaseio.com",
    projectId: "react-mcity-c3dd5",
    storageBucket: "react-mcity-c3dd5.appspot.com",
    messagingSenderId: "213630662924",
    appId: "1:213630662924:web:33e44e85325686336b3129"
  };

// Initialize Firebase
 const app = firebase.initializeApp(firebaseConfig);

 const auth = firebase.auth(app)

 const db = firebase.database();

 const storage = firebase.storage();

 const dbMatches = db.ref('matches');
 const dbPlayers = db.ref('players');
 const dbPromotions = db.ref('promotions');
 const dbTeams = db.ref('teams');
 const dbPositions = db.ref('positions');

export {auth, db, dbMatches, dbPlayers, dbPromotions, dbTeams, dbPositions, storage};