import React from "react";
import { Link } from "react-router-dom";

export const Tag = (props) => {
  const text = (
    <div
      style={{
        background: props.bck,
        fontSize: props.size,
        color: props.color,
        padding: "5px 10px",
        display: "inline-block",
        fontFamily: "Righteous",
        ...props.add,
      }}
    >
      {props.children}
    </div>
  );
  if (props.link) return <Link to={props.linkTo}>{text}</Link>;
  else return text;
};
